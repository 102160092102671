import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/Docs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Working with Ashu`}</h2>
    <p>{`Here's a bit of info about how I work best. Consider this my default API, but I'm happy to make adjustments and flex into your preferred mode of communication / engagement as needed. `}</p>
    <p>{`I'm looking forward to working together!`}</p>
    <h2>{`About me:`}</h2>
    <ol>
      <li parentName="ol">{`I spent the last 10 years operating a startup, so I've been in your shoes very recently.`}
        <ul parentName="li">
          <li parentName="ul">{`Managed KPIs, hired and coached our exec team.`}</li>
          <li parentName="ul">{`Designed our org culture and supported employee development and morale.`}</li>
          <li parentName="ul">{`Ran our fundraises ($35 million in equity and $8 million in debt).`}</li>
          <li parentName="ul">{`Did IC work across all domains (engineering, marketing, operations, finance).`}</li>
          <li parentName="ul">{`Heavy exposure to regulatory challenges (licensing and accreditation).`}</li>
        </ul>
      </li>
      <li parentName="ol">{`I won't be an expert in your domain, but I learn very quickly.`}
        <ul parentName="li">
          <li parentName="ul">{`If you think understanding something in your domain is important, send me resources to read via email.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`I'm well studied in education and community building with diverse groups of people`}</li>
    </ol>
    <h2>{`How to get the most out of me:`}</h2>
    <ol>
      <li parentName="ol">{`Talk fast, it'll help us do more in less time. I usually watch youtube videos at 2x speed :)`}</li>
      <li parentName="ol">{`I love asynchronous communication.`}
        <ul parentName="li">
          <li parentName="ul">{`If I can cover ground by reading information via email before or at the start of our sessions, that's great!`}</li>
          <li parentName="ul">{`If it's a quick question, use email. If it's a discussion or brainstorm, use the sessions.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`Cut to the chase. Start with the problem then share the context.`}
        <ul parentName="li">
          <li parentName="ul">{`Long winded backstories will usually eat up time.`}</li>
          <li parentName="ul">{`Caveat: if you do just need someone just to listen or do rubber ducky debugging, that's fine too. Just know we won't be able to cover as much ground in our time.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`Focus on the highest leverage problems.`}
        <ul parentName="li">
          <li parentName="ul">{`If a problem doesn't have high impact on your main KPIs, it's probably better to cover on email than in our sessions.`}</li>
          <li parentName="ul">{`Cofounder fights or challenges with key team members are usually high leverage. `}<strong parentName="li">{`Don't hide these`}</strong>{` , I promise I've dealt with worse.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`Generating ideas is one of my superpowers (Desai == ideas ;) so use me for this.`}
        <ul parentName="li">
          <li parentName="ul">{`Recognize that it's your job to figure out which ideas are actually good ones.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`I'll be very direct about what I think the biggest problem with your startup or your leadership mindset is.`}
        <ul parentName="li">
          <li parentName="ul">{`Sometimes this means I'll take the discussion in a different direction than you were planning on, and this usually will lead to good insights. Feel free to bring the discussion back to your agenda as needed.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`It may feel like I'm oversimplifying the problem when giving advice. That's intentional.`}
        <ul parentName="li">
          <li parentName="ul">{`In my experience, boiling things down to their essence and reducing scope and complexity is the best way to get to a clear answer. Clarity of thought is critical for good decision making.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`It may feel like my advice is too general to be true for your startup or for you as a leader.`}
        <ul parentName="li">
          <li parentName="ul">{`It's rare that startups or individual relationships are so special that general advice doesn't apply. Often coaching is just about resurfacing the right common knowledge at the right time, since it's easy to forget.`}</li>
          <li parentName="ul">{`My job is to get you out of the weeds and look at things from a macro/systems lens and understand the laws of startup physics or human nature that are at play.`}</li>
          <li parentName="ul">{`Your job is to apply these laws of physics to the nuances of your business and your approach to management.`}</li>
        </ul>
      </li>
    </ol>
    <h2>{`A few things to know:`}</h2>
    <ol>
      <li parentName="ol">{`I'm very direct in communicating and don't always sugar coat delivery. It's not always a fit for people if this risks driving intimidation/insecurity or if you are looking for a coach that leans heavier towards emotional support.`}</li>
      <li parentName="ol">{`Don't feel shy to give me feedback or ask for a different approach. Every relationship is different and I'm here to serve you. I feel coaching tends to work better if we feel comfortable with each other, see each other as humans (rather than holding rigid or transactional roles) and have open and direct communication.`}</li>
      <li parentName="ol">{`I tend to be lean more fluid than rigid with scheduling. This is to accomodate my own travel and deep work, and also to accomodate fundraising and urgent fires on your end. I may ask to reschedule meetings on short notice from time to time - and I'm very comfortable with you doing the same. I hold open plenty of availability so rescheduling with me is easy.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      